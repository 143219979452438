import { createTRPCReact } from '@trpc/react-query'
import type {
  AppRouter,
  RouterInput as Input,
  RouterOutput as Output,
} from '@v0-report/core/src/trpc/router'

export type TRPCInput = Input
export type TRPCOutput = Output

export const trpc = createTRPCReact<AppRouter>({})

// export const trpc = createTRPCNext<AppRouter>({
//   config() {
//     return {
//       links: [
//         httpBatchLink({
//           /**
//            * If you want to use SSR, you need to use the server's full URL
//            * @link https://trpc.io/docs/ssr
//            **/
//           url: `${getBaseUrl()}/api/trpc`,
//           // fetch: async (input, init?) => {
//           //   const fetch = getFetch()
//           //   return fetch(input, {
//           //     ...init,
//           //     credentials: 'include',
//           //   })
//           // },
//           // You can pass any HTTP headers you wish here
//           // async headers() {
//           //   const { data } = await supabase.auth.getSession()

//           //   return {
//           //     authorization: 'Bearer ' + data?.session?.access_token,
//           //   }
//           // },
//         }),
//       ],
//     };
//   },
//   /**
//    * @link https://trpc.io/docs/ssr
//    **/
//   ssr: false,
// });
