import type { NextEnv } from '../../types'

export const PublicEnv: NextEnv = {
  NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY as string,
  NEXT_PUBLIC_STRIPE_PUBLIC_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY as string,
  NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST as string,
  NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY as string,
  NEXT_PUBLIC_STAGE: process.env.NEXT_PUBLIC_STAGE as string,
  NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL as string,
  NEXT_PUBLIC_JWT_TEMPLATE: process.env.NEXT_PUBLIC_JWT_TEMPLATE as string,
  NEXT_PUBLIC_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL: process.env
    .NEXT_PUBLIC_CLERK_SIGN_IN_FALLBACK_REDIRECT_URL as string,
  NEXT_PUBLIC_CLERK_SIGN_IN_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_IN_URL as string,
  NEXT_PUBLIC_CLERK_SIGN_UP_FALLBACK_REDIRECT_URL: process.env
    .NEXT_PUBLIC_CLERK_SIGN_UP_FALLBACK_REDIRECT_URL as string,
  NEXT_PUBLIC_CLERK_SIGN_UP_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_UP_URL as string,
}

if (process.env.NODE_ENV === 'development') {
  Object.entries(PublicEnv).forEach(([key, value]) => {
    if (value === undefined) {
      throw new Error(`Missing environment variable ${key}`)
    }
  })
}
